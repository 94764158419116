import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147')
];

export const server_loads = [0,45,21,46,23,24,30,15,16,17,19,2,7,37,39,40,10,4,6,3];

export const dictionary = {
		"/(landings)/(home)": [~139,[45]],
		"/(hub)/admin": [82,[21]],
		"/(hub)/admin/impersonation": [~83,[21]],
		"/(hub)/admin/stats": [84,[22]],
		"/(hub)/admin/stats/teams": [~85,[22]],
		"/(iam)/auth/edu": [~131,[43]],
		"/(iam)/auth/invite": [~132],
		"/(iam)/auth/password": [~133],
		"/(iam)/auth/recover": [~134,[42]],
		"/(iam)/auth/signin": [~135,[42]],
		"/(iam)/auth/signup": [~136,[42]],
		"/(landings)/championship": [~140,[46]],
		"/(landings)/championship/disciplines": [141,[46]],
		"/(landings)/championship/documents": [142,[46]],
		"/(hub)/console": [86,[23]],
		"/(hub)/console/cms/[resource_id]/richtext": [87,[24]],
		"/(hub)/console/cms/[resource_id]/text": [~88,[25]],
		"/(hub)/console/codes": [~89,[23]],
		"/(hub)/console/codes/[id]": [~90,[26]],
		"/(hub)/console/crm/feedback": [~91,[23]],
		"/(hub)/console/crm/partnership": [~92,[23]],
		"/(hub)/console/crm/subscription": [~93,[23]],
		"/(hub)/console/organizations": [~94,[23]],
		"/(hub)/console/organizations/[id]": [~95,[27]],
		"/(hub)/console/organizations/[id]/groups": [~96,[27]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~97,[28]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~98,[28]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~99,[28]],
		"/(hub)/console/organizations/[id]/roles": [~100,[27]],
		"/(hub)/console/terms": [~101,[23]],
		"/(hub)/console/terms/[id]": [~102,[29]],
		"/(hub)/console/terms/[id]/map": [103,[29]],
		"/(hub)/console/users": [~104,[23]],
		"/(hub)/dev": [105,[30],[31]],
		"/(hub)/dev/examples": [~106,[32]],
		"/(hub)/dev/examples/await": [107,[32]],
		"/(hub)/dev/examples/scheduler": [108,[32]],
		"/(hub)/dev/examples/seaweedfs": [109,[32]],
		"/(hub)/dev/examples/xapi": [110,[32]],
		"/(hub)/dev/examples/yjs": [111,[32]],
		"/(hub)/dev/landing": [112,[33]],
		"/(hub)/dev/playground": [~113,[34]],
		"/(hub)/dev/playground/buttons": [114,[34]],
		"/(hub)/dev/playground/confirm": [115,[34]],
		"/(hub)/dev/playground/dates": [116,[34]],
		"/(hub)/dev/playground/gantt": [117,[34]],
		"/(hub)/dev/playground/inputs": [~118,[34]],
		"/(hub)/dev/playground/modal": [119,[34]],
		"/(hub)/dev/playground/popover": [120,[34]],
		"/(hub)/dev/playground/switch": [121,[34]],
		"/(hub)/dev/playground/tabs copy": [123,[34]],
		"/(hub)/dev/playground/tabs": [122,[34]],
		"/(hub)/dev/playground/toasts": [124,[34]],
		"/(events)/events/[slug]": [73,[15]],
		"/(events)/events/[slug]/[event_id]/[[blueprint_id]]": [74,[15,16,17]],
		"/(home)/facilitator": [80,[19]],
		"/(landings)/faststart": [143],
		"/(education)/groups": [~57,[2]],
		"/(education)/groups/[id]": [~58,[7]],
		"/(education)/groups/[id]/terms": [~59,[7]],
		"/(home)/home": [~81,[19]],
		"/(home)/(invitations)/invitations_international": [~78,[19,20]],
		"/(home)/(invitations)/invitations_limited": [~79,[19,20]],
		"/(home)/(invitations)/invitations": [~77,[19,20]],
		"/legal/user-agreement": [147,[47]],
		"/(hub)/lms": [125,[35],[36]],
		"/(hub)/lms/[type=blueprint_type]": [~126,[35],[36]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [128,[37,39,40],[38]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [129,[37,39],[38]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~127,[37,39],[38]],
		"/(hub)/mentorship": [130,[41]],
		"/(landings)/opencup": [~144],
		"/(landings)/opencup/disciplines": [145],
		"/(landings)/opencup/documents": [146],
		"/(education)/org/[org_id]": [~60,[2]],
		"/(education)/org/[org_id]/events": [62,[9]],
		"/(education)/org/[org_id]/events/[term_id]/teams": [~63,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/personal": [~65,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/team": [66,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/[group_id]": [~64,[10],[,11]],
		"/(education)/org/[org_id]/teams/[group_id]": [~67,[2]],
		"/(education)/org/[org_id]/[slug]": [~61,[8]],
		"/(external)/platform/alg": [~75],
		"/(external)/platform/standings/[standing_id]": [76,[18]],
		"/(education)/rewards": [~68,[2,12]],
		"/(education)/rewards/acknowledgments": [70,[2,12]],
		"/(education)/rewards/certificates": [~71,[2,12]],
		"/(education)/rewards/diplomas": [~72,[2,12]],
		"/(education)/rewards/[reward_id]": [~69,[2,12],[,,13]],
		"/(iam)/user": [~137,[44]],
		"/(iam)/user/security": [~138,[44]],
		"/(education)/[type=term_type]": [~48,[2]],
		"/(education)/[type=term_type]/international": [~52,[4]],
		"/(education)/[type=term_type]/international/events": [53,[5]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams": [~54,[6]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams/[group_id]": [~55,[6]],
		"/(education)/[type=term_type]/international/teams/[group_id]": [~56,[4]],
		"/(education)/[type=term_type]/[slug]": [~49,[3]],
		"/(education)/[type=term_type]/[slug]/teams/[group_id]": [~51,[3]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~50,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';