import { eventType } from '$schemas/events';
import type { ParamMatcher } from '@sveltejs/kit';

export function isEventType(value: string): value is DB.Events.Enum.EventType {
  return eventType.enumValues.includes(value as TODO);
}

export const match: ParamMatcher = (param) => {
  return isEventType(param);
};
